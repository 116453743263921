import { computed, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';

import useRecipeItem from '@/modules/cms/composables/recipes/useRecipeItem';

const useRecipeIngredients = () => {
  const store = useStore();

  const { selectedRecipe } = useRecipeItem();

  const addIngredient = { amount: '', unit: '', id: '' };
  const currentAddIngredient = ref('');
  const currentAddIngredientUnits = ref([]);

  const isLoadingAddUnits = ref(false);
  const isLoadingAddIngredient = ref(false);

  const addIngredientShowMsg = ref(false);
  const addIngredientMsg = ref('');
  const addIngredientMsgStatus = ref('form-');

  const updateRecipeUnitsAdd = async ({ id }) => {
    isLoadingAddUnits.value = true;
    addIngredient.id = id;

    const { status, msg, units } = await store.dispatch(
      'cmsRecipes/updateRecipeUnitsEdit',
      id
    );
    isLoadingAddUnits.value = false;
    if (status) {
      currentAddIngredientUnits.value = units;
    } else {
      addIngredientMsgStatus.value += status ? 'success' : 'error';
      addIngredientShowMsg.value = true;
      addIngredientMsg.value = msg;

      setTimeout(() => {
        addIngredientShowMsg.value = false;
        addIngredientMsg.value = '';
        addIngredientMsgStatus.value = 'form-';
      }, 2800);
    }
  };

  const resetAddMessage = () => {
    addIngredientShowMsg.value = false;
    addIngredientMsg.value = '';
    addIngredientMsgStatus.value = 'form-';
  };

  const addRecipeIngredient = async () => {
    const isFullFields =
      Object.values(addIngredient).filter((value) => value === '').length === 0;

    if (isFullFields) {
      isLoadingAddIngredient.value = true;
      const { status, msg, recipeID } = await store.dispatch(
        'cmsRecipes/addRecipeIngredient',
        addIngredient
      );

      setTimeout(() => {
        isLoadingAddIngredient.value = false;
        addIngredientMsgStatus.value += status ? 'success' : 'error';
        addIngredientMsg.value = msg;
        addIngredientShowMsg.value = true;

        setTimeout(() => {
          resetAddMessage();
          if (status) selectedRecipe(recipeID);
        }, 2500);
      }, 2800);
    } else {
      addIngredientShowMsg.value = true;
      addIngredientMsg.value = 'Llene todos los campos';
      addIngredientMsgStatus.value += 'error';

      setTimeout(() => {
        resetAddMessage();
      }, 2800);
    }
  };

  watchEffect(() => {
    const ingredient = currentAddIngredient.value;
    if (typeof ingredient === 'string' || !ingredient) return;
    updateRecipeUnitsAdd(ingredient);
  });

  const currentEditIngredient = ref('');
  const currentEditUnit = ref('');
  const isEditIngredient = ref(false);
  const isLoadingEditUnits = ref(false);
  const isLoadingEditIngredient = ref(false);

  const isLoadingDeleteIngredient = ref(false);

  const editIngredientShowMsg = ref(false);
  const editIngredientMsg = ref('');
  const editIngredientMsgStatus = ref('form-');

  const editRecipeIngredient = ({
    idIngredient,
    idRecipeIngredient,
    unitList,
    name,
  }) => {
    store.commit('cmsRecipes/editRecipeIngredient', {
      idIngredient,
      idRecipeIngredient,
      unitList,
    });
    currentEditIngredient.value = name;
    isEditIngredient.value = true;
  };

  const updateRecipeUnitsEdit = async ({ id }) => {
    isLoadingEditUnits.value = true;

    const { status, msg } = await store.dispatch(
      'cmsRecipes/updateRecipeUnitsEdit',
      id
    );
    isLoadingEditUnits.value = false;

    if (!status) {
      editIngredientMsgStatus.value += status ? 'success' : 'error';
      editIngredientShowMsg.value = true;
      editIngredientMsg.value = msg;

      setTimeout(() => {
        resetEditMessage();
      }, 2800);
    }
  };

  watchEffect(() => {
    const ingredient = currentEditIngredient.value;
    if (typeof ingredient === 'string' || !ingredient) return;
    updateRecipeUnitsEdit(ingredient);
  });

  const resetEditMessage = () => {
    editIngredientShowMsg.value = false;
    editIngredientMsgStatus.value = 'form-';
    editIngredientMsg.value = '';
  };

  const validateFormFieldsEdit = () => {
    const units = currentEditUnit.value.options;
    const currentUnit = units[currentEditUnit.value.selectedIndex];
    const isEmptyUnit = currentUnit.text.includes('Seleccione');

    resetEditMessage();

    isLoadingEditIngredient.value = true;

    if (currentEditIngredient.value === '' || !currentEditIngredient.value) {
      isLoadingEditIngredient.value = false;

      editIngredientMsgStatus.value += 'error';
      editIngredientShowMsg.value = true;
      editIngredientMsg.value = 'Seleccione un ingrediente';

      setTimeout(() => {
        resetEditMessage();
      }, 2800);
      return true;
    } else if (isEmptyUnit) {
      isLoadingEditIngredient.value = false;

      editIngredientMsgStatus.value += 'error';
      editIngredientShowMsg.value = true;
      editIngredientMsg.value = 'Seleccione una unidad';

      setTimeout(() => {
        resetEditMessage();
      }, 2800);
      return true;
    }

    return false;
  };

  const cancelEdition = () => {
    store.commit('cmsRecipes/editRecipeIngredient', {
      idIngredient: null,
      idRecipeIngredient: null,
      unitList: [],
    });
    currentEditIngredient.value = '';
    isEditIngredient.value = false;
  };

  const updateRecipeIngredient = async ({ unit, amount }) => {
    if (validateFormFieldsEdit()) return;

    const { status, recipeID, msg } = await store.dispatch(
      'cmsRecipes/updateRecipeIngredient',
      {
        amount,
        unit,
      }
    );

    setTimeout(() => {
      isLoadingEditIngredient.value = false;
      editIngredientMsgStatus.value += status ? 'success' : 'error';
      editIngredientShowMsg.value = true;
      editIngredientMsg.value = msg;

      setTimeout(() => {
        resetEditMessage();
        selectedRecipe(recipeID);
      }, 2500);
    }, 2800);
  };

  const deleteRecipeIngredient = async (idRecipeIngredient) => {
    isLoadingDeleteIngredient.value = true;

    const { status, recipeID, msg } = await store.dispatch(
      'cmsRecipes/deleteRecipeIngredient',
      idRecipeIngredient
    );
    setTimeout(() => {
      isLoadingDeleteIngredient.value = false;
      editIngredientMsgStatus.value += status ? 'success' : 'error';
      editIngredientShowMsg.value = true;
      editIngredientMsg.value = msg;

      setTimeout(() => {
        resetEditMessage();
        selectedRecipe(recipeID);
      }, 2500);
    }, 2800);
  };

  return {
    ingredients: store.getters['cmsIngredients/getIngredientsList'],
    ingredientEdit: computed(
      () => store.getters['cmsRecipes/getRecipeIngredientEdit']
    ),
    recipeIngredients: store.getters['cmsRecipes/getRecipeIngredients'],

    addRecipeIngredient,
    isLoadingAddIngredient,
    currentAddIngredient,
    currentAddIngredientUnits,
    addIngredient,
    isLoadingAddUnits,
    addIngredientShowMsg,
    addIngredientMsg,
    addIngredientMsgStatus,

    isLoadingEditUnits,
    isLoadingEditIngredient,
    editRecipeIngredient,
    currentEditIngredient,
    currentEditUnit,
    editIngredientShowMsg,
    editIngredientMsg,
    editIngredientMsgStatus,
    updateRecipeIngredient,

    isLoadingDeleteIngredient,
    deleteRecipeIngredient,

    cancelEdition,
  };
};
export default useRecipeIngredients;
