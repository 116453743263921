<template>
  <div class="mb-2">
    <Subtitle subtitle="Ingredientes" />

    <FormAddIngredient />

    <Separator v-if="recipeIngredients.length > 0" />

    <div
      class="list-ingredients recipes"
      v-if="recipeIngredients.length > 0"
      :class="[recipeIngredients.length >= 2 ? 'scroll' : '']"
    >
      <template
        v-for="({
          id,
          nombre,
          cantidad,
          unidad_medida,
          todas_unidades,
          id_listado,
        },
        index) in recipeIngredients"
        :key="index"
      >
        <FormEditIngredient
          :index="index"
          :idRecipeIngredient="id_listado"
          :idIngredient="id"
          :amount="cantidad"
          :name="nombre"
          :unit="unidad_medida"
          :unitList="todas_unidades"
        />
      </template>
    </div>

    <Empty v-else notFound="ingredientes" />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import useRecipeIngredients from '@/modules/cms/composables/recipes/useRecipeIngredients';

export default {
  components: {
    Subtitle: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Subtitle" */ '@/modules/cms/components/Subtitle.vue'
      )
    ),
    Empty: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Empty" */ '@/modules/cms/components/Empty.vue'
      )
    ),
    Separator: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Separator" */ '@/modules/cms/components/Separator.vue'
      )
    ),
    FormAddIngredient: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "FormAddIngredient" */ '@/modules/cms/parts/recipes/ingredients/FormAddIngredient.vue'
      )
    ),
    FormEditIngredient: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "FormEditIngredient" */ '@/modules/cms/parts/recipes/ingredients/FormEditIngredient.vue'
      )
    ),
  },
  setup() {
    const { recipeIngredients } = useRecipeIngredients();
    return {
      recipeIngredients,
    };
  },
};
</script>
